
/* ボックスレイアウト
=========================================== */
$box_min: 1;
$box_max: 12;
@for $i from $box_min through $box_max {
  .flex.col_#{$i}{
    >div,>li{
      width:#{(100 / $i)}#{'%'};
      @media only screen and (max-width: 768px){
        width: 100%;
      }
    }
  }
  .flex.flex-between.col_#{$i}{
    >div,>li{
      width:#{(100 / $i - 1.5)}#{'%'};
      @media only screen and (max-width: 768px){
        width: 100%;
      }
    }
  }
}