@charset "UTF-8";

@import "mixin_common";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;900&display=swap');

$color_main: #002067;
$color_sub: #0096c7;
$color_bg: #EDF1F2;
$color_acc: #ff7700;
$color_grad: linear-gradient(90deg, #006AC7, #00BDD2);
$color_accGrad: linear-gradient(90deg, #E63700, #E66B00);

// =================================================
//                  ページ設定
// =================================================
* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
            box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
html{
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
  -webkit-text-size-adjust:100%;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;

  @media (max-width: 768px) {
    scroll-padding-top: 80px;
  }
}

body{
  color: #000;
  -webkit-text-size-adjust:100%
}

body.mypage-register-create,
body.mypage-register-confirm{
  text-align: center;
  margin: 0 auto;
}

img {
  image-rendering:auto;
  image-rendering: -webkit-optimize-contrast;
}

picture{
  display: block;
}

.linkBox{
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding-bottom: 0;
  &:before {
    display: none;
  }
  &:hover{
    color: #{$color_sub};
  }
}
figure{
  img{
    max-width: 100%;
    vertical-align: bottom;
  }
}
.inner{
  text-align: center;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;

  @media screen and (max-width: 1100px) {
    max-width: none;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
}
.sp {
  display: none;
  @media only screen and (max-width: 768px) {
    display: inline;
  }
}

@media only screen and (max-width: 768px) {
  .pc {
    display: none;
  }
}

input[type=submit], button, input{
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
}

ul, li, ol{
  list-style: none;
}

/* font size
================================= */
html{
  font-size: 62.5%;
}
body{
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;/* 16px*/
  line-height: 1.8;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  body{
    line-height: 1.7;
    font-size: 1.4rem;
  }
  p{
    font-size: 1.4rem;
  }
}

.en{
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.04em;
}

.rubik{
  font-family: 'Rubik', sans-serif;
}

/* iOSでのデフォルトスタイルをリセット
================================= */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]:-webkit-search-decoration,
input[type="button"]:-webkit-search-decoration {
  display: none;
}
input[type="submit"]:focus,
input[type="button"]:focus {
  outline-offset: -2px;
}

/* clearfix
================================= */
.clearfix:before,
.clearfix:after {
    content:"";
    display:block;
    overflow:hidden;
}
.clearfix:after {
    clear:both;
}
.clearfix {
    zoom:1;
}
.left{
  float: left;
}
.right{
  float: right;
}


/* リンク
================================= */

a{
  text-decoration: none;
  color: #{$color_sub};
  transition: all .2s ease-in-out;
  line-height: 1.4;
  position: relative;
  display: inline-block;

  @media (hover:hover) {
    &:hover{
      color: #{$color_sub};
      opacity: 0.7;
  
      &:before {
        opacity: 1;
      }
    } 
  }
}

button{
  cursor: pointer !important;
}

/* flexbox
================================= */
.flex{
  display:-webkit-box;
  display:-moz-box;
  display:-ms-box;
  display:-webkit-flexbox;
  display:-moz-flexbox;
  display:-ms-flexbox;
  display:-webkit-flex;
  display:-moz-flex;
  display:-ms-flex;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px){
    flex-direction: column;
  }
}
.flex.flex-between{
  justify-content: space-between;
}
.flex.reverse{
  flex-direction: row-reverse;
  @media only screen and (max-width: 768px){
    flex-direction: row;
  }
}
@media only screen and (max-width: 768px){
  .spFlexRow{
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

section{
  img{
    max-width: 100%;
  }
  br.pc{
    @media only screen and (max-width: 768px){
      display: none;
    }
  }
  br.sp{
    display: none;
    @media only screen and (max-width: 768px){
      display: block;
    }
  }
}

.tright{
  text-align: right;
  margin-top: 10px;
}

main{
  padding-top: 113px;

  @media (max-width: 768px) {
    padding-top: 68px;
  }
}


// =================================================
//                  header
// =================================================
header{
  background: #fff;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0px 8px 10px 0 rgba(0, 32, 103, 0.05);
  transition: .3s all ease-in-out;

  a{
    color: #333333;
  }

  >.flex.flex-between{
    align-items: center;
    width: 94%;
    margin: 0 auto;
    flex-wrap: nowrap;
  }

  //header top
  .header_top{
    padding: 6px 0;
    border-bottom: 1px solid #EEEEEE;

    >.flex.flex-between{
      align-items: center;
      width: 90%;
      margin: 0 auto;
      flex-wrap: nowrap;
    }

    h1{
      font-size: 1.2rem;
      font-weight: 500;
      white-space: pre-wrap;

      @media (max-width: 993px) {
        font-size: 1rem;
      }
    }
  }

  .header_top_content{
    ul{
      align-items: center;
      flex-wrap: nowrap;
      gap: 16px;

      @media (max-width: 993px) {
        gap: 8px;
      }
    }

    li{
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 16px;
      white-space: nowrap;

      &:before, &:after{
        content: "";
        position: absolute;
        background: #707070;
        border-radius: 16px;
        width: 7px;
        height: 2px;
        left: 0;
      }

      &:before{
        top: 6px;
        transform: rotate(45deg);

        @media (max-width: 993px) {
          top: 5px;
        }
      }

      &:after{
        top: 9.5px;
        transform: rotate(-45deg);

        @media (max-width: 993px) {
          top: 9px;
        }
      }

      a{
        font-size: 1.2rem;
        font-weight: 500;

        @media (max-width: 993px) {
          font-size: 1rem;
        }
      }
     
      //言語
      &.lang{
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
      
        &:before{
          background: url(/img/common/icon_lang.svg) no-repeat;
          background-size: contain;
          width: 14px;
          height: 14px;
          top: 2px;
          transform: none;

          @media (max-width: 993px) {
            top: 1px;
          }
        }

        &:after{
          content: none;
        }

        a:first-child{
          position: relative;
          padding-right: 10px;

          &:after{
            content: "/";
            position: absolute;
            font-size: 1.2rem;
            font-weight: 500;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }

        a:last-child{
          padding-left: 5px;
        }
      }

      //user_name
      &.user_name{
        font-size: 1.2rem;
        color: #0096C7;
        padding: 0;
        display: block;

        @media (max-width: 993px) {
          font-size: 1rem;
        }
        
        &:before, &:after{
          content: none;
        }
      }
    }
  }

  //header bottom
  .header_bottom{
    padding: 12px 0;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    
    @media (max-width: 768px) {
      padding: 10px 0;
      height: 68px;
    }

    .logo{
      max-width: 288px;
      width: 100%;

      @media (max-width: 768px) {
        max-width: 112px;
      }

      >img{
        width: 100%;
        height: 100%;
        vertical-align: bottom;
      }
    }
  }

  .header_bottom_content{
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 20px;
    width: 62%;

    @media (max-width: 768px) {
      gap: 8px;
    }

    a{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;

      @media (max-width: 768px) {
        gap: 4px;
      }

      >img{
        width: 28px;
        height: 28px;
      }

      >p{
        font-size: 1.2rem;
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 1.0rem;
          font-weight: 500;
        }
      }
    }

    .join{
      max-width: 190px;
      width: 100%;
      height: 48px;

      @media (max-width: 768px) {
        max-width: unset;
        width: unset;
      }

      a{
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;

        @media (max-width: 768px) {
          color: #333333;
          font-size: 1.1rem;
        }
        
        span{
          position: relative;
          padding-left: 28px;

          &:before{
            content: "";
            position: absolute;
            background: url(/img/common/icon_register_white.svg) no-repeat;
            background-size: contain;
            width: 22px;
            height: 22px;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  //hamburger
  .hamburger{
    position: relative;
    width: 36px;
    height: 28px;
    top: 3px;
    cursor: pointer;
    z-index: 1000;

    @media (max-width: 768px) {
      width: 30px;
      height: 22px;
      top: 0;
    }

    span{
      position: absolute;
      background: $color_sub;
      border-radius: 24px;
      width: 100%;
      height: 3px;
      transition: .4s all ease-in-out;
      right: 0;
    }

    span:first-child{
      top: 0;
    }

    span:nth-child(2){
      top: 10px;
    }

    span:last-child{
      top: 20px;
    }

    //active
    &.active{
      span{
        transition: .4s all ease-in-out;
        top: 3px;

        @media (max-width: 993px) {
          top: 7px;
        }

        @media (max-width: 768px) {
          top: 10px;
        }
      }

      span:first-child{
        transform: rotate(315deg);
      }

      span:nth-child(2){
        transform: rotate(-315deg);
      }
  
      span:last-child{
        transform: rotate(-315deg);
      }
    }
  }

  //hamburger menu
  .hamburger_menu{
    position: fixed;
    right: -130%;
    top: 0;
    width: 42%;
    height: 100%;
    background: #fff;
    transition: .4s all;
    z-index: 200;
    overflow-y: auto;

    @media (max-width: 993px) {
      width: 48%;
    }

    @media (max-width: 768px) {
      width: 100%;
      border-top: 1px solid #DDDDDD;
      top: 68px;
    }

    .menu_content{
      padding: 0 40px;

      @media (max-width: 993px) {
        padding: 0 20px;
      }

      @media (max-width :768px) {
        padding: 0;
        margin-bottom: 100px;
      }
    }

    //hamburger_logo
    .hamburger_logo{
      padding-top: 54px;
      padding-bottom: 60px;
      max-width: 220px;
      width: 100%;

      img{
        width: 100%;
        height: 100%;
        vertical-align: bottom;
      }
    }

    //user_cta
    .user_cta{
      justify-content: center;
      gap: 20px;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        margin: 24px 0;
        padding: 0 20px;
      }
    }

    .btn_mainB, .btn_orangeG{
      max-width: 210px;
      width: 100%;
      height: 42px;

      @media (max-width: 768px) {
        max-width: 160px;
      }

      a{
        width: 100%;
        height: 100%;
        color: $color_sub;
        padding: 10px 0;
        font-size: 1.5rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
          font-size: 1.4rem;
        }

        span{
          position: relative;
          padding-left: 26px;

          &:before{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .btn_mainB{
      transition: all .2s ease-in-out;

      a{
        color: #fff;
        span:before{
          background: url(/img/common/icon_login_white.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    .btn_orangeG{
      a{
        color: #fff;

        span:before{
          background: url(/img/common/icon_register_white.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    //menu_main
    .menu_main{
      width: 100%;
      margin-bottom: 60px;

      @media (max-width :768px) {
        margin-bottom: 40px;
      }

      li{
        border-bottom: 1px solid #DDDDDD;

        >a{
          width: 100%;
          padding: 16px 12px;
          font-size: 1.6rem;
          font-weight: bold;
          position: relative;
          padding-left: 24px;
          text-align: left;

          @media (max-width :768px) {
            font-size: 1.4rem;
          }

          &:before{
            content: "";
            position: absolute;
            left: 0;
          }
        }
      }
    }

    //user_menu
    .user_menu{
      li{
        a{
          padding-left: 28px;
          text-align: left;
          @media (max-width: 768px) {
            padding-left: 40px;
          }

          &:before{
            top: 22px;
            border-top: 2px solid $color_sub;
            border-right: 2px solid $color_sub;
            transform: rotate(45deg);
            width: 7px;
            height: 7px;
            left: 6px;
            @media (max-width: 768px) {
              left: 20px;
            }
          }
        }
      }
    }

    //mypage_menu
    .mypage_menu{

      @media (max-width: 768px) {
        margin-top: 40px; 
      }

      li{
        a{
          padding-left: 40px;
          @media (max-width: 768px) {
            padding-left: 48px;
          }

          &:before{
            width: 20px;
            height: 20px;
            top: 16px;
            left: 12px;
            @media (max-width: 768px) {
              left: 20px;
            }  
          }
        }

        &.search_icon a:before{
          background: url(/img/common/icon_search.svg) no-repeat;
          background-size: contain;
        }

        &.mypage a:before{
          background: url(/img/common/icon_mypage.svg) no-repeat;
          background-size: contain;
        }

        &.like a:before{
          background: url(/img/common/icon_fav.svg) no-repeat;
          background-size: contain;
        }

        &.entry a:before{
          background: url(/img/common/icon_apply.svg) no-repeat;
          background-size: contain;
        }

        &.info a:before{
          background: url(/img/common/icon_userinfo.svg) no-repeat;
          background-size: contain;
        }
      }

      li:last-child{
        border-bottom: none;
      }

      .mypage_other_content{
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;

        @media (max-width: 768px) {
          margin-top: 14px;
          padding: 0 20px;
        }

        button{
          border: none;
          background: none;
          font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
        }

        a, button{
          font-size: 1.4rem;
          font-weight: 500;
          position: relative;
          color: #333333;
          padding-left: 20px;
          vertical-align: text-top;
          
          &:before{
            content: "";
            position: absolute;
            border-top: 2px solid #BBBBBB;
            border-right: 2px solid #BBBBBB;
            transform: rotate(45deg);
            width: 7px;
            height: 7px;
            left: 0;
            top: 6px;
          }

          @media (hover:hover) {
            &:hover{
              opacity: 0.7;
              color: $color_sub;
            } 
          }
        }
      }
    }

    //other_menu
    .other_menu{
      align-items: flex-start;
      flex-wrap: nowrap;

      @media (max-width :768px) {
        padding: 0 20px;
        margin-bottom: 24px;
        gap: 40px;
      }

      .other_menu_content{
        width: 50%;
        // padding: 0 12px;

        @media (max-width :993px) {
          padding: 0;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      p{
        font-size: 1.6rem;
        font-weight: bold;
        text-align: left;
        margin-bottom: 20px;

        @media (max-width :768px) {
          font-size: 1.4rem;
        }
      }

      li{
        padding-bottom: 16px;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
        line-height: 1;

        a{
          position: relative;
          padding-left: 20px;

          &:before{
            content: "";
            position: absolute;
            border-top: 2px solid #BBBBBB;
            border-right: 2px solid #BBBBBB;
            width: 7px;
            height: 7px;
            left: 0;
            top: 5px;
            transform: rotate(45deg);
          }
        }
      }

      li:last-child{
        padding-bottom: 0;
      }
    }

    //lang
    .lang{
      padding: 0 20px;

      @media (max-width: 768px) {
        display: block !important;
      }

      .flex{
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 20px;

        &:before{
          content: "";
          position: absolute;
          background: url(/img/common/icon_lang.svg) no-repeat;
          width: 16px;
          height: 16px;
          left: 0;
          top: 2px;
        }
      }
      a{
        font-size: 1.4rem;
        font-weight: 500;

        &:first-child{
          position: relative;
          padding-right: 10px;

          &:after{
            content: "/";
            position: absolute;
            font-size: 1.4rem;
            font-weight: 500;
            right: 0;
            top: 0;
          }
        }

        &:last-child{
          padding-left: 5px;
        }
      }
    }
  }
}

//hamburger menu 背景
.menu-bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #333;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
  cursor: pointer;

  @media screen and (max-width: 768px) {
     top: 68px;
  }
}

//hamburger menu active body
.nav-open{
  z-index: -1;
  width: 100%;

  header{
    top: 0 !important;
  }

  .menu-bg{
    opacity: 0.8;
    visibility: visible;
  }

  .hamburger_menu{
    right: 0;
  }
}

// =================================================
//                  breadcrumb
// =================================================
.breadcrumb {
  padding-top: 5px;
  margin-bottom: 60px;
  text-align: left !important;
  li{
    display: inline;
    list-style: none;
    font-size: 1.2rem;
    &:after {
      content: '>';
      padding: 0 0.5em;
    }
    &:last-child:after {
    content: '';
    }
    a{
      &:before {
        opacity: 0;
      }

      @media (hover:hover) {
        &:hover{
          &:before {
            opacity: 1;
          }
        } 
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 40px;
    li{
      font-size: 1.1rem;
    }
  }
}


// =================================================
//                    key
// =================================================
.key{
  text-align: center;
  width:100%;
  margin-left: auto;
  margin-right: auto;
  h2{
    font-size: 3rem;
    margin-bottom: 1.66em;
    @media only screen and (max-width: 768px){
      font-size: 2.4rem;
    }
    span.en{
      display: block;
      color: #{$color_sub};
    }
  }
}


// =================================================
//                  cookie-consent
// =================================================
.cookie-consent {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 100%;
  max-width: 360px;
  background: rgba(0,0,0,.7);
  z-index: 10000;
  visibility: hidden;
  @media only screen and (max-width: 768px){
    left: 0;
    bottom: 0;  
    max-width: 100%;
  }
}
.cookie-consent .inner{
   position: relative;
   margin-bottom: 0;
   box-sizing: border-box;
   padding: 1.6em;
   @media only screen and (max-width: 1100px){
    width: 100%;
    margin: 0;
   }
   @media only screen and (max-width: 768px){
    padding: 1.4em;
   }
}

.cookie-consent.is-show {
  visibility: visible;
}

.cookie-consent .close{
  cursor: pointer;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  >span{
    position: absolute;
    background: #fff;
    width: 100%;
    height: 2px;
    border-radius: 2px;
  }

  >span:first-child{
    transform: rotate(45deg);
  }
  
  >span:last-child{
    transform: rotate(135deg);
  }
}

.cookie-content{
  // display: flex;
  // justify-content: space-around;
  // align-items: center;
  font-size: 1.2rem;
  color: #fff;
}

.cookie-text{
  text-align: left;
  margin-bottom: 20px;
  @media screen and (max-width: 987px) {
    br{
      display: none;
    }
  }
}
.cookie-consent a {
  color: #fff !important;
  &:before{
    background: #fff !important;
  }
}
.cookie-agree {
  color: #fff;
  background: #{$color_sub};
  padding: .5em 1.5em;
  white-space: nowrap;
  margin-left: 10px;
  max-width: 200px;
  margin: 0 auto;
}
.cookie-agree:hover {
  cursor: pointer;
  opacity: 0.8;
}
/* パッと消える */
.cc-hide1 {
  display: none;
}
/* ゆっくり消える */
.cc-hide2 {
  animation: hide 1s linear 0s;
  animation-fill-mode: forwards;
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
/* メディアクエリ */
@media screen and (max-width: 768px) {
  .cookie-consent {
    flex-direction: column;
  }
  .cookie-text {
    margin-bottom: 1em;
  }
}


// =================================================
//                  footer
// =================================================
footer{
  background: #F5F5F5;
  padding: 87px 0 60px;

  @media (max-width: 768px) {
    padding: 60px 0 160px;
  }

  a{
      color: #1D1D1D;

      &:before{
          content: none;
      }

      &:hover{
        opacity: 1;
      }
  }

  .inner{
    margin-bottom: 0;
  }

  .footer_top{
      flex-wrap: wrap;
      align-items: flex-start;
      padding-bottom: 60px;
      border-bottom: 1px solid #CCCCCC;
      align-items: stretch;

      @media (max-width: 768px) {
        border-bottom: none;
        padding-bottom: 40px;
      }
  }

  .footer_top.inner{
      @media (max-width: 768px) {
          max-width: 100%;
          width: 100%;
          margin: 0;
      }
  }

  .footer_list{
      align-items: flex-start;
      justify-content: space-between;
      width: 47%;

      @media (max-width: 993px) {
        flex-direction: column;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 40px;
      }
  }

  .footer_list.service{
    width: unset;
    padding-top: 40px;
    
    &:before{
      content: none;
    }

    @media (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .navlist{
      width: 280px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    dd{
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    dd:nth-of-type(2n-1){
        @media (max-width: 768px) {
          border-right: none;
        }
    }

    dd:nth-of-type(2){
      @media (max-width: 768px) {
        border-top: none;
      }
    }
  }

  .service.inner{
    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      margin: 0;
  }
  }

  .navlist{
      text-align: left;
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }

      >dt{
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 10px;

          @media (max-width: 768px) {
            padding-left: 20px;
          }
      }

      >dd{
          padding-bottom: 10px;

          @media (max-width: 768px) {
            width: 50%;
            float: left;
            padding: 14px 0 14px 12px;
            border-bottom: 1px solid #CCCCCC;
          }
      }

      dd:nth-of-type(2n-1){
        @media (max-width: 768px) {
          border-right: 1px solid #CCCCCC;
        }
      }

      dd:nth-of-type(1), dd:nth-of-type(2){
        @media (max-width: 768px) {
          border-top: 1px solid #CCCCCC;
        }
      }

      >dd a{
        font-size: 1.4rem;
        position: relative;
        display: block;
        padding-bottom: 0;
        padding-left: 20px;

        @media (max-width: 560px) {
          font-size: 1.3rem;
          padding-left: 16px;
        }

        &:after{
            content: "";
            position: absolute;
            border-top: 2px solid #CCCCCC;
            border-right: 2px solid #CCCCCC;
            width: 6px;
            height: 6px;
            left: 0;
            top: 6px;
            transform: rotate(45deg);

            @media (max-width: 768px) {
              right: 8px;
              top: 6px;
            }
        }
    }
}

  .user, .company{
    padding-top: 20px;
    flex-direction: row;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }

      >p{
        margin-bottom: 10px;

        @media (max-width: 768px) {
          border: none;
          padding: 0;
          margin-bottom: 0;
          max-width: 160px;
          width: 100%;
        }

        &:after{
          @media (max-width: 768px) {
            right: 9px;
            top: 23px;
          }
        }
      }

      >p:last-child{
        margin-bottom: 0;
      }

      >p a{
        border-radius: 24px;
        width: 180px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s ease-in-out;
        padding-bottom: 0;

        @media (max-width: 768px) {
          width: 100%;
          max-width: 100%;
        }

        &:after{
            content: none;
        }

        >span{
          color: #fff;
          font-size: 1.4rem;
          font-weight: bold;
          display: inline-block;
          position: relative;
        }
      }
  }

  .footer_middle{
      padding-top: 60px;

      @media (max-width: 768px) {
        padding-top: 0;
      }

      .footer_logo{
        @media (max-width: 768px) {
          text-align: left;
          margin-bottom: 40px;
        }

          >a{
              margin-bottom: 20px;
          }

          img{
              max-width: 304px;
              width: 100%;
          }

          >p{
              font-size: 1.4rem;
              text-align: left;
              margin-bottom: 10px;
          }

          >p:last-child{
              margin-bottom: 0;
          }
      }

      .operate{
          >ul li{
              margin-bottom: 10px;
              text-align: right;

              @media (max-width: 768px) {
                text-align: left;
              }
          }

          >ul li:last-child{
              margin-bottom: 0;
          }

          a{
              font-size: 1.4rem;
              padding: 0;
          }
      }
  }

  .footer_under{
      padding-top: 40px;
      align-items: center;

      @media (max-width: 768px) {
        align-items: flex-start;
        gap: 20px;

      }
      
      .sns_wrapper{
          justify-content: start;
          align-items: center;
          gap: 20px;

          @media (max-width: 768px) {
            flex-direction: row;
          }
      }

      .copy{
          font-size: 1.4rem;
          text-align: right;
      }
  }

  //topページに戻る(ScrollTop)
  .ScrollTop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    background: #{$color_sub};
    box-shadow: 0px 10px 18px 2px rgba(96, 118, 129, 0.15);
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    opacity: 0;
    transition: all .2s ease-in-out;
    z-index: 100;
    &:before{
      display: none;
    }
    @media only screen and (max-width: 768px) {
      right: 10px;
      bottom: 10px;
    }
  }
  .ScrollTop.isActive {
    opacity: 1;
    transition: all .2s ease-in-out;
  }
  
  .ScrollTop span{
    width: 10px;
    height: 10px;
    border: 2px solid;
    border-color: #fff #fff transparent transparent;
    transform: rotate(-45deg);
    position: absolute;
    top: 22px;
  }
  
  .grecaptcha-badge{
    bottom: 80px !important;
  }
}

// =================================================
//                  見出し
// =================================================

//********************* リニューアル

/* 文字グラデーション(font-grad)
================================= */
.font-grad{
  background: linear-gradient(90deg, #006AC7, #00BDD2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* タイトル (sec_title)
================================= */
.sec_title{
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }

  h2, h3{
    font-size: 3.2rem;
    font-weight: bold;
    color: #333333;
    line-height: 1.5;
    display: block;

    @media (max-width: 768px) {
      font-size: 2.4rem;
    }
  }

  span{
    display: block;
    font-size: 2.4rem;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 12px;
  
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
}


/* 一覧ボックス (job_list & one_job)
================================= */
.job_list{
  align-items: stretch;
  justify-content: center;
  gap: 46px;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
}

.one_job{
  width: 30%;
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 993px) {
    width: 46%;
  }

  @media (max-width: 560px) {
    width: 100%;
  }
  
  >a{
    width: 100%;
  }

  >a img{
    height: 165px;
    object-fit: cover;
    vertical-align: bottom;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  .job_box{
    padding: 24px 20px;

    >dt{
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2.8rem;
      color: #000000;
      text-align: left;
      margin-bottom: 15px;
      transition: all .2s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: calc(2.8rem*2);
      min-height: calc(2.8rem*2);
    }

    >dd{
      color: #707070;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    span{
      margin-right: 10px;
    }
  }
}

/* ボタン
================================= */
.btn_submit, .btn_enter, .btn_grad{
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: all .2s ease-in-out;

  @media (hover:hover) {
    &:hover{
      opacity: 0.7;
    } 
  }

  a, button, input[type=submit]{
    width: 100%;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 17px 0;

    @media (max-width: 768px) {
      font-size: 1.6rem;
     }

     &:hover{
      opacity: unset;
     }
  }
}

//背景色：グラデーション
.btn_submit{
  max-width: 300px;
  background: $color_grad;
  margin: 60px auto 0;

  @media (max-width: 768px) {
   margin-top: 40px;
   max-width: 240px;
  }
 
  a, button{
    color: #fff;
  }
}

//blue グラデーション
.btn_grad{
  max-width: 300px;
  width: 100%;
  background: $color_grad;
  border-radius: 40px;
  margin: 60px auto 0;

  @media (max-width: 768px) {
    max-width: 240px;
    margin-top: 40px;
  }

   a, button{
    color: #fff;
  }

  @media (hover:hover) {
    &:hover{
      background: linear-gradient(90deg, #0077C7, #00CBD2);
      transition: all .2s ease-in-out;
      opacity: 1;
  
      a{
        opacity: 1;
      }
    } 
  }
}

//blue グラデーション
.btn_blueG{
  color: #fff;
  background: $color_grad;
  border-radius: 40px;
  transition: all .2s ease-in-out;

  @media (hover:hover) {
    &:hover{
      color: #fff;
      background: linear-gradient(90deg, #0077C7, #00CBD2);
      transition: all .2s ease-in-out;
  
      a{
        opacity: 1;
      }
    } 
  }
}

//orange グラデーション
.btn_orangeG{
  color: #fff;
  background: $color_accGrad;
  border-radius: 40px;
  transition: all .2s ease-in-out;

  @media (hover:hover) {
    &:hover{
      color: #fff;
      background: linear-gradient(90deg, #E64917, #E68E44);
      transition: all .2s ease-in-out;
  
      a{
        opacity: 1;
      }
    } 
  }
}

//navy グラデーション
.btn_navyG{
  color: #fff;
  background: linear-gradient(90deg, #00338B, #007CCF);
  border-radius: 40px;
  transition: all .2s ease-in-out;

  @media (hover:hover) {
    &:hover{
      color: #fff;
      background: linear-gradient(90deg, #00338B, #007CCF);
      transition: all .2s ease-in-out;
  
      a{
        opacity: 1;
      }
    } 
  }
}

//背景：白・線：メイン色
.btn_mainB{
  // border: 2px solid $color_sub;
  background: #fff;
  border-radius: 40px;

  a, input[type=submit]{
    color: $color_sub;
  }

  @media (hover:hover) {
    &:hover{
      background: #eef2fb;
    } 
  }
}

//背景色：白
.btn_enter{
  max-width: 280px;
  background: #fff;

  a, button{
    color: $color_sub;

    span{
      position: relative;
      display: inline-block;
    }
  }
}

//背景：なし・線：青
.btn_bluebord{
  background: none;
  border: 2px solid $color_sub;
  border-radius: 40px;

  @media (hover:hover) {
    &:hover{
      opacity: 0.7;
    } 
  }
}

//背景・線： メインカラー
.btn_blue{
  background: $color_sub;
  border-radius: 40px;

  @media (hover:hover) {
    &:hover{
      opacity: 0.7;
    } 
  }
}


/* 最近見た求人 slider
================================= */
.inner_list{
  padding-bottom: 120px;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  .inner{
    margin-bottom: 0;
  }

  .job_list.flex{
    gap: 15px;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .one_job{
    width: calc(100% / 4);

    @media (max-width: 768px) {
      width: 48%;
      margin-bottom: 20px;
    }

    @media (max-width :560px) {
      width: 100%;
    }
  }

  //slider全体枠
  .slick-track{
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  //slider矢印
  .slick-prev, .slick-next{
    background: $color_sub;
    opacity: 0.8;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    z-index: 10000;

    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
    }

    &:before, &:after{
      content: "";
      position: absolute;
      display: inline-block;
      width: 3px;
      height: 12px;
      background: #fff;
      border-radius: 10px;
      opacity: 1;
      right: 20px;

      @media (max-width: 768px) {
        right: 17px;
      }
    }

    &:before{
      top: 13px;

      @media (max-width: 768px) {
        top: 9px;
      }
    }

    &:after{
      top: unset;
      bottom: 12px;

      @media (max-width: 768px) {
        bottom: 8px;
      }
    }
  }

  //prev
  .slick-prev{
    left: unset;
    right: calc(50% + 39vw);

    @media (max-width: 560px) {
      right: calc(50% + 148px);
    }

    &:before{
      transform: rotate(45deg);
    }

    &:after{
      transform: rotate(-45deg);
      top: unset;
    }
  }

  //next
  .slick-next{
    right: unset;
    left: calc(50% + 39vw);

    @media (max-width: 560px) {
      left: calc(50% + 148px);
    }

    &:before{
      transform: rotate(-45deg);
    }

    &:after{
      transform: rotate(45deg);
    }
  }
}





//********************* 元
.h1_bg{
  width: 100%;
  height: 360px;
  position: relative;
  background: url("/img/mv.jpg") center no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 180px;
    margin-top: 80px;
  }
  h1 {
    width: 100%;
    font-size: 4.2rem;
    letter-spacing: 0.04em;
    color: #fff;
    font-weight: 700;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    line-height: 1.3;
    text-shadow: 1px 1px 3px #333;
    .en{
      display: block;
      font-size: 28px;/* IE8以下とAndroid4.3以下用フォールバック */
      font-size: 2.8rem;
      font-weight: normal;
      margin-top: 13px;
      display: block;
    }
    @media screen and (max-width: 768px) {
      font-size: 2.7rem;
      .en{
        font-size: 1.7rem;
        margin-top: 8px;
      }
    }
  }
}

h2.ttl,h3.ttl {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 58px;
  line-height: 1.7;
  text-align: center;
  color: #000;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 40px;
  }
  &:before {
    display: inline-block;
    position: absolute;
    content: '';
    bottom: -20px; /*下線の上下の位置*/
    left: 50%;
    width: 60px; /*下線の幅*/
    height: 2px; /*下線の太さ*/
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%); /*位置の調整*/
    background-color: #{$color_sub}; /*下線の色*/
  }
}



/* table
=========================================== */

.tbl01{
  border-collapse: collapse;
  width: 100%;
  th {
    color: #000;
    background: #{$color_bg};
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 1.8;
    white-space: nowrap;
    border: 1px solid #dddddd;
    font-size: 1.6rem;
  }
  td {
    padding: 30px;
    line-height: 1.8;
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 1.6rem;
    .flow_ttl{
      margin-top: 2rem;
    }
    ul{
      margin-bottom: 10px;
    }
    p,li{
      font-size: 1.6rem;
    }
    .small{
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 768px){
    width: 98%;
    border-top: 1px solid #ddd;
    th{
      display: block;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 1.5rem;
      border-top: none;
      border-bottom: none;
    }
    td{
      padding: 15px;
      display: block;
      width: 100%;
      font-size: 1.5rem;
      p,li{
        font-size: 1.5rem;
      }
    }
  }
}

.tbl01.pd20{
  td {
    padding: 20px;
  }
  @media only screen and (max-width: 768px){
    padding: 15px;
  }
}

.tbl02{
  border-collapse: collapse;
  width: 100%;
  th {
    color: #{$color_main};
    background: #{$color_bg};
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
    padding: 10px 25px;
    line-height: 1.8;
    border: 1px solid #dddddd;
    font-size: 1.6rem;
  }
  td {
    padding: 10px 25px;
    line-height: 1.8;
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 768px){
    th{
      padding-left: 15px;
      padding-right: 15px;
      font-size: 1.5rem;
      vertical-align: middle;
    }
    td{
      padding-left: 15px;
      padding-right: 15px;
      font-size: 1.5rem;
      vertical-align: middle;
    }
    .nowrap{
      white-space: nowrap;
    }
  }
}


/* list
=========================================== */

ul{
  list-style: none;
}

.def{
  dd{
    margin-bottom: 2rem;
  }
}

.square{
  margin-bottom: 2rem;
  dt{
    position: relative;
    padding-left: 1em;
    &::before{
      background: #{$color_main};
      content: "";
      height: 10px;//四角のサイズ
      width: 10px;//四角のサイズ
      left: 0;
      position: absolute;
      top: 10px;
    }
  }
  dd{
    margin-bottom: 0.8rem;
  }
}

.maru{
  li,dd{
    padding-left: 0.5rem;
    display: list-item;
    margin-left: 1.5em;
    list-style-type: disc;
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 1em;
    @media only screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
}

.num{
  counter-reset: li;
  li{
    position: relative;
    padding-left: 1.8rem;
    &::before{
      position: absolute;
      top: 0px;
      left: 0;
      color: #{$color_main};
      font-weight: 700;
      counter-increment: li;   /* li の数を一つずつ増加 */
      content: counter(li) ". ";          /* 数を表示 */
    }
  }
}


/* .list
=========================================== */

.list a{
  display: block;
  //margin-bottom: 40px;
}

.list .col_3 > div a,
.list .col_4 > div a{
  img{
    width: 100%;
    height: auto;
    vertical-align: bottom;
    border-left: 1px solid #cbd0dc;
    border-top: 1px solid #cbd0dc;
    border-right: 1px solid #cbd0dc;
  }
  .box{
    border-left: 1px solid #cbd0dc;
    border-bottom: 1px solid #cbd0dc;
    border-right: 1px solid #cbd0dc;
    padding: 25px 25px 22px 25px;
  }
  .btn{
    display: block;
    width: 180px;
    margin: 20px auto 0 auto;
    border: 1px solid #{$color_main};
    border-radius: 18px;
    color: #{$color_main};
    font-size: 1.4rem;
    line-height: 1;
    padding: 10px 0 12px 0;
    text-align: center;
    transition: all .2s ease-in-out;
    @media only screen and (max-width: 1100px) {
      width: 90%;
    }
  }

  @media (hover:hover) {
    &:hover{
      .btn{
        color: #{$color_sub};
        border: 1px solid #{$color_sub};
      }
    } 
  }
}

.list .flex.flex-between.col_3,
.list .flex.flex-between.col_4{
  justify-content: flex-start;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  > div{
    margin-right: 0.75%;
    margin-left: 0.75%;
    @media only screen and (max-width: 768px) {
      width: 48%;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.list .col_3 > div a,
.list .col_4 > div a{
  dt{
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.8rem;
    color: #000;
    text-align: left;
    margin-bottom: 15px;
    transition: all .2s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: calc(2.8rem*2);
    min-height: calc(2.8rem*2);
  }
  dd{
    font-size: 1.5rem;
    line-height: 1.7;
    color: #000;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (hover:hover) {
    &:hover{
      dt{
        color: #{$color_sub};
      }
    } 
  }
}

.list .col_4 > div a{
  li{
    font-size: 1.4rem;
    line-height: 1.6;
    color: #000;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.list .c_name{
  font-weight: bold;
}

.about p{
  text-align: center;
  line-height: 2.2;
  margin-bottom: 1.5em;
  @media only screen and (max-width: 1100px){
    text-align: left;
    line-height: 2;
    br{
      display: none;
    }
  }
}

.form_text {
  margin-bottom: 20px;
  // @media only screen and (max-width: 1100px){
  //   text-align: left;
  // }
}

.form_link {
  margin-top: 40px;
  @media only screen and (max-width: 1100px){
    text-align: left;
  }
}


/* mypage-password-request
=========================================== */

body.mypage-password-request,
body.mypage-password-email-thanks,
body.mypage-password-reset,
body.mypage-verification-notice{
  background:#{$color_bg};
  text-align: center;
  margin: 0 auto;
  .notification{
    border: none;
    background-color: transparent;
  }
  .in{
    width: 90%;
    max-width: 560px;
    background:#fff;
    padding: 33px 0 60px 0;
    text-align: center;
    margin: 0 auto 100px;

    @media only screen and (max-width: 767px) {
      padding: 20px 0 40px 0;
      margin-bottom: 60px;
    }
    div.pass{
      margin-top: 22px;
      @media only screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }
    div.autologin{
      margin-top: 40px;
      margin-bottom: 20px;
      margin-left: 12px;
      text-align: left;
    }
    h1{
      font-size: 2rem;
      margin: 0 auto 39px auto;
      @media only screen and (max-width: 767px) {
        font-size: 1.6rem;
        margin: 0 auto 15px auto;
      }
    }
    p{
      text-align: left;
      margin-bottom: 5px;
    }
    p.exposition{
      margin-bottom: 20px;
    }
    p.kome{
      margin-top: 5px;
      padding-bottom: 10px;
      font-size: 1.4rem;
    }
  }
}

.resetform{
  width: 82%;
  text-align: center;
  margin: 0 auto;
  input[type=text],
  input[type=email],
  input[type=password]{
    width: 100%;
    padding: 10px 8px;
    font-size: 16px;
    border: 1px solid #707070;
  }
  .autologin label {
    display: block;
    cursor: pointer;
    input[type=checkbox] {
      visibility: hidden;
      position: absolute;
      &:checked + span:after{
        opacity: 1;
      }
    }
  }
  .autologin span {
    line-height: 23px;
    position: relative;
    padding-left: 26px;
    display: block;
    @media screen and (max-width: 767px) {
      line-height: 30px;
    }
    &:before {
      content: "";
      position: absolute;
      left: -12px;
      top: 0;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: 1px solid #707070;
      @media screen and (max-width: 767px) {
        top: 3px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: -6px;
      top: 6px;
      width: 13px;
      height: 7px;
      background: #fff;
      transform: rotate(-45deg);
      border-bottom: 3px solid;
      border-left: 3px solid;
      opacity: 0;
      -webkit-transition: opacity .2s;
      -o-transition: opacity .2s;
      transition: opacity .2s;
      @media screen and (max-width: 767px) {
        top: 9px;
      }
    }
  }
  .btn_submit{
    text-align: center;
    margin: 30px auto 0 auto;
    @media only screen and (max-width: 767px) {
      margin-top: 15px;
    }
    input[type=submit],button.loginbtn {
      display: inline-block;
      min-width: 320px;
      color: #fff;
      border: 2px solid #{$color_sub};
      background: #{$color_sub};
      border-radius: 30px;
      text-align: center;
      font-size: 1.8rem;
      font-weight: bold;
      padding: 12px 0px;
      position: relative;
      outline: none;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition   : all .2s ease-in-out;
      transition: all .2s ease-in-out;

      @media (hover:hover) {
        &:hover{
          background-color: #fff;
          border-color: #{$color_sub};
          color: #{$color_sub};
        } 
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        min-width: 240px;
        max-width: 260px;
        font-size: 1.6rem;
      }
    }
  }
  .link{
    text-align: center;
    margin: 25px auto 0 auto;
    font-size: 1.4rem;
  }
  .pass .link{
    text-align: left;
    margin: 10px 0 0 0;
  }
  .toggle-password-wrap{
    position: relative;
    input{
      padding-right: 40px;
    }
    button{
      position: absolute;
      bottom: calc(50% - 15px);
      right: 11px;
      border: none;
      background: none;
      font-size: 25px;
      color: #aaa;
      cursor: pointer;
    }
  }
}

.flex-textarea {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.8;

  .flex-textarea-dummy {
    overflow: hidden;
    visibility: hidden;
    box-sizing: border-box;
    padding: 5px 15px;
    min-height: 120px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    border: 1px solid;
  }

  .flex-textarea-textarea {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    padding: 5px 15px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #b6c3c6;
    border-radius: 4px;
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
    resize: none;
  }

  .flex-textarea-textarea:focus {
    box-shadow: 0 0 0 4px rgba(35, 167, 195, 0.3);
    outline: 0;
  }
}
